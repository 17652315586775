import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import {
	Layout,
	Helmet,
	Section,
	Container,
	Logo,
	PhoneIcon,
	MailIcon,
	PinIcon,
	LinkedInIcon,
	FacebookIcon,
	GoogleMap,
	ContactForm
} from 'components'

const ContactPage = () => (
	<Layout className='contacts-page' hideFooter>
		<Helmet
			title='Nomade, gestion immobilière'
			description='Notre mission, assurer la qualité de vie de nos locataires, dans nos immeubles résidentiels, en offrant des espaces locatifs novateurs et un service-client exemplaire.'
			url='/contacts'
		/>
		<Section grey>
			<Container>
				<div className='flex contacts-flex'>
					<div>
						<h1>
							CONTACTEZ-NOUS POUR
							<br />
							UNE VISITE OU POUR
							<br />
							DES INFORMATIONS
							<br />
						</h1>
						<h2>Remplissez notre formulaire !</h2>
					</div>
					<div className='contacts'>
						<div>
							<PinIcon />
							1255, boulevard Lebourgneuf, bur. 560, Québec (Qc) G2K 0M6
						</div>
						<div>
							<PhoneIcon />
							<a href="tel:+14185691599">(418) 569-1599</a>
						</div>
						<div>
							<MailIcon />
							<a href="mailto:sboivin@nomadegestion.com">Écrivez-nous</a>
						</div>
					</div>
				</div>
				<ContactForm />
			</Container>
			<div className='map'>
				<GoogleMap />
			</div>
		</Section>
	</Layout>
)

export default ContactPage
